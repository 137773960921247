<template>
  <div class="commentBox flex-column-center">
    <div class="headLine flex-row-center">
      <img
        class="titleImg"
        src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/commentTitle.png"
        alt=""
      >
      <div class="commentNum">共<span style="color:#DB4B48;">{{this.postData.replied_cnt}}</span>條</div>
    </div>
    <!-- 单个评论 -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="initList"
    >
      <div
        class="commentItemBox"
        v-for="(item,index) in replyList"
        :key="index"
        @click="goCommentReply(item)"
      >
        <div class="userLine flex-row-center">
          <img
            class="userIcon"
            :src="item.user.avatar"
            alt=""
            @click.stop="goUserInfo(item.user.id)"
          >
          <div class="userInfoBox">
            <div class="userName">{{item.user.nickname}}</div>
            <div class="userTitle">{{item.user.official}}</div>
          </div>
          <img
            @click.stop="showActionModal(item.user.id, item.id)"
            class="moreIcon"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/moreIcon.png"
            alt=""
          >
        </div>
        <div class="commentText">{{item.content}}
        </div>
        <div
          class="moreComment"
          v-if="item.replied_cnt>0"
        >查看<span style="color:#DB4B48;">{{item.replied_cnt}}條</span>回復 ></div>

        <!-- <div
          class="commentReply"
          @click="goCommentReply(item)"
        >
          <div class="commentReplyText"><span style="color:#DB4B48;">王小花</span>：对呀，这个设计师特别厉害，设计的东西 都很漂亮呢</div>
          <div class="commentReplyText"><span style="color:#DB4B48;">王小花</span>：对呀，这个设计师特别厉害，设计的东西 都很漂亮呢</div>
          <div class="moreComment">查看<span style="color:#DB4B48;">2條</span>回復 ></div>
        </div> -->
        <div class="actionLine flex-row-center">
          <div class="commentTime">{{getTime(item.create_time)}}</div>
          <div class="flex-row-center">
            <img
              class="actionIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/commentIcon.png"
              alt=""
            >
            <div class="actionNum">{{item.replied_cnt}}</div>
            <img
              v-show="!item.liked"
              class="actionIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/zanIcon.png"
              alt=""
              @click.stop="goLikeComment(item,index)"
            >
            <img
              v-show="item.liked"
              class="actionIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/redZanIcon.png"
              alt=""
              @click.stop="goDisLikeComment(item,index)"
            >
            <div class="actionNum">{{item.liked_cnt}}</div>
          </div>
        </div>
        <div class="commentDivider"></div>

      </div>
      <!-- 分割线 -->
    </van-list>
  </div>
</template>
<script type="text/ecmascript-6">
import ActionModal from '../../components/actionModal/ActionModal.vue'
import { getReplyList } from '../../api/service'
import { getFormateTime } from '../../util/timeUtil'
import { likeComment, cancelLikeComment } from '../../api/service'
import { Toast } from 'vant'

export default {
  name: 'PostComment',
  components: {},
  props: {
    postData: {}
  },
  data() {
    return {
      postId: '',
      replyList: [],
      cursor: '',
      loading: false,
      finished: false
    }
  },
  mounted() {
    this.postId = this.$route.query.postId
  },
  methods: {
    initList() {
      getReplyList(this.postId, {
        cursor: this.cursor,
        page_size: 5
      }).then((res) => {
        if (res.code == 0) {
          this.cursor = res.data.cursor
          res.data.list.map((item) => {
            this.replyList.push(item)
          })
          this.loading = false

          if (!res.data.cursor) {
            this.finished = true
          }
        }
      })
    },
    refresh() {
      this.replyList = []
      this.cursor = ''
      this.initList()
    },
    getTime(time) {
      return getFormateTime(parseInt(time))
    },
    goCommentReply(replyItem) {
      this.$router.push({
        path: 'commentReply',
        query: {
          replyId: replyItem.id,
          postId: this.postId,
          rootId: replyItem.root_id
        }
      })
    },
    goLikeComment(item, index) {
      likeComment(item.id).then((res) => {
        if (res.code == 0) {
          this.replyList[index].liked = true
          this.replyList[index].liked_cnt++
          Toast('點贊成功')
        }
      })
    },
    goDisLikeComment(item, index) {
      cancelLikeComment(item.id).then((res) => {
        if (res.data.code == 0) {
          this.replyList[index].liked = false
          this.replyList[index].liked_cnt--
          Toast('取消成功')
        }
      })
    },
    showActionModal(userId, id) {
      this.$emit('showActionModal', userId, id, 'comment')
    },
    goUserInfo(id) {
      this.$router.push({
        path: 'Author',
        query: {
          userId: id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './PostComment';
</style>
<template>
  <div class="postDetail flex-column-center">
    <div class="detailBox flex-column-center">
      <div class="userLine">
        <img
          class="userIcon"
          :src="userInfo.avatar"
          alt=""
          @click="goUserInfo(userInfo.id)"
        >
        <div class="userName">{{userInfo.nickname}}</div>
        <div class="userTitle">{{userInfo.official}}</div>
        <!-- 关注按钮 -->
        <div
          class="followBtn flex-row-center"
          v-show="!postDetail.user_followed && userInfo.id != localUserId"
        >
          <img
            class="followIcon"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/followIcon.png"
            alt=""
          >
          <div
            class="followText"
            @click="goFollowUser"
          >關注</div>
        </div>
        <!-- 取消关注按钮 -->
        <div
          class="unFollowBtn"
          v-show="postDetail.user_followed && userInfo.id != localUserId"
          @click="goUnFollow"
        >已關注</div>
        <img
          @click="showActionModal(postDetail.user.id,postDetail.id,'post')"
          class="postActionIcon"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/moreIcon.png"
          alt=""
        >
      </div>
      <div class="postTitle">{{postDetail.title}}</div>
      <p class="postContent">{{postDetail.content}}
      </p>
      <div
        class="imgList"
        v-if="postDetail.images && postDetail.images.length>0"
      >
        <img
          class="imgItem"
          v-for="(item,index) in postDetail.images"
          :key="index"
          :src="item"
          alt=""
        >
      </div>
      <div
        class="videoBox flex-column-center"
        v-if="videoUrl"
      >
        <div id="player"></div>
      </div>
      <div
        class="voteBox flex-column-center"
        v-if="voteId"
      >
        <div class="voteTitle">{{voteData.title}}
          {{voteData.isMulti?'（多选）':'（单选）'}}
        </div>
        <div class="optionList">
          <div
            class="optionItemBox"
            v-for="(item,index) in voteData.options"
            :key="index"
          >
            <div class="optionText">{{item.tag}}</div>
            <img
              class="optionUnCheck"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/check0.png"
              alt=""
              :style="`opacity: ${voteData.joined?'0.3':'1'};`"
              @click="chooseOption(index,true)"
              v-show="!item.status"
            >
            <img
              class="optionUnCheck"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/check1.png"
              alt=""
              :style="`opacity: ${voteData.joined?'0.3':'1'};`"
              @click="chooseOption(index,false)"
              v-show="item.status"
            >
            <div class="optionDataLine flex-row-center">
              <div class="progressBox">
                <div
                  class="inner"
                  :style="`width:${item.percent};`"
                ></div>
              </div>
              <div class="optionDataText">{{item.voteNum}}票 | {{item.percent}}</div>
            </div>
          </div>
        </div>
        <div
          class="confirmVoteBtn"
          :style="`opacity: ${hasChoose?'1':'0.3'};`"
          @click="goVote"
        >確定</div>
      </div>
      <div class="divider"></div>
      <div class="postInfoLine flex-row-center">
        <img
          class="eye"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/eys.png"
          alt=""
        >
        <div class="postInfoNum">{{viewed_cnt}}</div>
        <img
          class="thumb"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/zanIcon.png"
          alt=""
        >
        <div class="postInfoNum">{{liked_cnt}}</div>
        <div class="postTime">發佈於 {{create_time}}</div>
      </div>
    </div>
    <!-- 帖子评论 -->
    <PostComment
      v-if="postDetail"
      :postData="postDetail"
      ref="postComment"
      @showActionModal="showActionModal"
    ></PostComment>
    <CommentBar
      :postId="postId"
      type="post"
      @replySuccess="replySuccess"
      :likeNum="liked_cnt"
      likeType="post"
      :likedStatus.sync="liked"
      @refreshPost="initDetail"
    ></CommentBar>
    <ActionModal ref="actionModal"></ActionModal>
  </div>
</template>
<script type="text/ecmascript-6">
import ActionModal from '../../components/actionModal/ActionModal.vue'
import CommentBar from '../../components/commentBar/CommentBar.vue'
import PostComment from '../../components/postComment/PostComment.vue'
import {
  postDetail,
  voteDetail,
  goVote,
  followUser,
  unFollowUser
} from '../../api/service'
import Player from 'xgplayer'
import { Toast } from 'vant'
import { getFormateTime } from '../../util/timeUtil'

let player
export default {
  name: 'PostDetail',
  components: {
    ActionModal,
    CommentBar,
    PostComment
  },
  computed: {
    hasChoose: function () {
      if (this.voteData.joined) {
        return false
      }
      for (const item of this.voteData.options) {
        if (item.status) {
          return true
        }
      }
      return false
    }
  },
  data() {
    return {
      postId: '',
      voteData: {
        title: '',
        isMulti: false,
        joined: false,
        options: []
      },
      commentList: [
        {
          userIcon: ''
        }
      ],
      postTitle: '',
      postDetail: {},
      videoUrl: '',
      voteId: null,
      userInfo: {
        id: null,
        nickname: '',
        official: '',
        avatar: '',
        followed: ''
      },
      viewed_cnt: 0,
      liked_cnt: 0,
      replied_cnt: 0,
      create_time: '',
      liked: false,
      localUserId: null
    }
  },
  created() {
    this.localUserId = localStorage.userId
    this.postId = this.$route.query.postId
    this.initDetail()
    player = null
  },
  methods: {
    initDetail() {
      postDetail(this.postId).then((res) => {
        if (res.code == 0) {
          this.postDetail = res.data
          this.userInfo = res.data.user
          this.viewed_cnt = res.data.viewed_cnt
          this.liked_cnt = res.data.liked_cnt
          this.replied_cnt = res.data.replied_cnt
          this.liked = res.data.liked
          this.create_time = getFormateTime(parseInt(res.data.create_time))

          if (res.data.video && !player) {
            this.videoUrl = res.data.video.url || null
            this.$nextTick(() => {
              player = new Player({
                id: 'player',
                url: this.videoUrl,
                videoInit: true
              })
            })
          }
          if (res.data.vote_id && !this.voteData.title) {
            this.voteId = res.data.vote_id
            this.initVoteData()
          }
        }
      })
    },
    initVoteData() {
      voteDetail(this.voteId).then((res) => {
        if (res.code == 0) {
          this.voteData.title = res.data.name
          this.voteData.isMulti = res.data.multi
          res.data.options.map((item) => {
            this.voteData.options.push({
              tag: item.name,
              status: false,
              voteNum: item.num,
              percent: item.percent,
              optionId: item.id
            })
            // 判断用户是否参与,加载参与信息
            if (res.data.my_votes.length > 0) {
              this.voteData.joined = true
              this.voteData.options.map((nItem, index) => {
                res.data.my_votes.map((mItem) => {
                  if (mItem == nItem.optionId) {
                    this.voteData.options[index].status = true
                  }
                })
              })
            }
          })
        }
      })
    },
    // 参与投票
    goVote() {
      if (!this.hasChoose) {
        return
      }
      if (this.voteData.joined) {
        return
      }
      let params = { oids: [] }
      this.voteData.options.map((item) => {
        if (item.status) {
          params.oids.push(item.optionId)
        }
      })
      goVote(this.voteId, params).then((res) => {
        if (res.code == 0) {
          Toast('投票成功')
          this.voteData = {
            title: '',
            isMulti: false,
            joined: false,
            options: []
          }
          this.initVoteData()
        }
      })
    },
    chooseOption(index, status) {
      if (this.voteData.joined === true) {
        return
      }
      if (status && !this.voteData.isMulti) {
        this.voteData.options.map((item) => {
          item.status = 0
          return item
        })
      }
      this.voteData.options[index].status = status
    },
    replySuccess() {
      this.$refs.postComment.refresh()
    },
    goCommentReply() {
      this.$router.push({
        path: 'commentReply'
      })
    },
    showActionModal(userId, id, type) {
      this.$refs.actionModal.showModal(userId, id, type)
    },
    goUserInfo(userId) {
      this.$router.push({
        path: 'Author',
        query: {
          userId: userId
        }
      })
    },
    goFollowUser() {
      followUser(this.userInfo.id).then((res) => {
        if (res.code == 0) {
          this.initDetail()
          Toast('操作成功')
        } else {
          Toast(res.msg)
        }
      })
    },
    goUnFollow() {
      unFollowUser(this.userInfo.id).then((res) => {
        if (res.data.code == 0) {
          this.initDetail()
          Toast('操作成功')
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './PostDetail';
</style>